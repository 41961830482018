import React from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function cardPost( { post } ) {

  const title = post.title
  const category = {
    name: post.categories.nodes[0].name,
    uri: post.categories.nodes[0].uri
  }
  const featuredImage = {
    image: getImage(post.featuredImage?.node?.localFile),
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Link to={post.uri} className="relative transition" >
      <li key={post.uri}>
        <div className="bg-white shadow-xl hover:shadow transition rounded">

          <GatsbyImage image={featuredImage.image} alt={featuredImage.alt} className="rounded-t xl:max-h-28" />

          <header className="flex flex-col gap-y-3 p-6 xl:p-2 xl:tracking-tighter ">
            <div className="flex flex-row items-center justify-between text-xs">
              <Link to={category.uri} className="px-2 py-1 bg-greenQS rounded-full text-white">{category.name}</Link>
              <span>{post.date}</span>
            </div>
            <div className="postHeaderSize overflow-auto h-full flex items-center justify-center">
              <h2 className="text-2xl xl:text-base font-semibold hover:text-greenQS transition" itemProp="headline">{parse(title)}</h2>
            </div>
          </header> 

        </div>
      </li>
    </Link>
  )
}

export default cardPost