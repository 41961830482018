import React from "react"
import { Link } from "gatsby"

import CategorySection from "../layout/categorySection"

import logoFooter from "../../images/layout/logo-fundopreto.svg";
import facebook from "../../images/layout/facebook.svg";
import instagram from "../../images/layout/instagram.svg";
import pinterest from "../../images/layout/pinterest.svg";
import youtube from "../../images/layout/youtube.svg";
import logo from "../../images/layout/logo-principal.svg";
import search from "../../images/layout/search-icon.svg";

function Layout404({children}) {

  return (
    <div className="w-full xl:w-7/12 h-full flex flex-col bg-hailGray">

      <header className="h-1/6 hidden w-full xl:flex flex-row items-center justify-between text-base xl:mx-auto xl:max-w-screen-md">
        <Link to="/"><img className="flex w-32" src={logo} alt="Logo da Quanto Sobra"/></Link>
        <Link className="hidden xl:flex" to="/">Dicas de Gestão</Link>
        <a className="hidden xl:flex" href="https://www.quantosobra.com.br/materiais-educativos/">Materiais Gratuitos</a>
        <a className="flex h-7 px-4 bg-greenQS rounded text-white text-center capitalize" href="https://app.useawise.com">
          Testar sistema de gestão <strong className="uppercase font-medium"> de graça</strong>
        </a>
      </header>

      <header className="header fixed xl:hidden h-16 w-full bg-white shadow inset-0 z-20">

        <div className="flex justify-center items-center h-full z-10 mx-auto max-w-xs text-lg font-medium">

          <div className="flex items-center">
            <Link to="/"><img className="flex w-44" src={logo} alt="Logo da Quanto Sobra"/></Link>
          </div>

          <div className="absolute right-8">
            <a href="javascript:setSandwichMenuVisibility()" className="menuButton flex items-center text-black">
              <svg className="h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
            </a>
          </div>

        </div>

        <div className="sandwichMenu hidden relative w-screen h-screen pt-20 flex-col items-center gap-20 z-10 bg-white text-2xl font-medium text-center">

          <button><img className="flex h-10" src={search} alt="Icone de pesquisa"/></button>
          <a href="https://blog.quantosobra.com.br/category/dicas-gestao/">Dicas de Gestão</a>
          <a href="https://www.quantosobra.com.br/materiais-educativos/">Materiais Gratuitos</a>
          <a className="px-4 bg-greenQS rounded text-white text-center capitalize" href="https://app.useawise.com">
            Testar sistema de <br /> gestão <strong className="uppercase font-medium">de graça</strong>
          </a>

          <div className="absolute bottom-0 h-24 bg-greenQS w-full"></div>
        </div>

      </header>

      <main className="flex flex-col mb-10 xl:mb-0 mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-md">
        {children}
      </main>

      <footer className="bg-strongGray xl:bg-hailGray text-white">

        <div className="flex flex-row items-start justify-between xl:justify-end py-8 xl:py-2 mx-auto w-full h-full max-w-xs md:max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-md">

          <div className="w-full md:w-96 xl:w-auto xl:mt-4 2xl:mt-8 flex flex-col gap-8 xl:gap-0">
            <Link to="/"><img alt="logo awise" className="w-36 xl:hidden" src={logoFooter} /></Link>
            <p className="text-sm xl:hidden">O blog do QuantoSobra traz, semanalmente, artigos com 
            materiais e as melhores dicas de gestão para pequenos empresários varejistas.</p>
            <div className="flex flex-row gap-4">
              <a href="https://www.facebook.com/QuantoSobra"><img src={facebook} alt="icone do facebook" className="w-12 h-12 bg-white rounded-full"></img></a>
              <a href="https://www.instagram.com/quanto.sobra/"><img src={instagram} alt="icone do instagram" className="w-12 h-12 bg-white rounded-full"></img></a>
              <a href="https://br.pinterest.com/QuantoSobra/"><img src={pinterest} alt="icone do pinterest" className="w-12 h-12 bg-white rounded-full"></img></a>
              <a href="https://www.youtube.com/channel/UCCk0bltUk3AeCjmm45IbKuQ"><img src={youtube} alt="icone do youtube" className="w-12 h-12 bg-white rounded-full"></img></a>
            </div>
          </div>

          <section className="w-96 hidden md:flex flex-col gap-8 text-sm xl:hidden">
            <CategorySection />
          </section>

        </div>

      </footer>

    </div>
  )
}

export default Layout404