import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import CardPost from "../components/404/cardPost404"
import Layout from "../components/404/layout404"

import error404 from "../images/erro404.svg"
import erro404mobile from "../images/erro404mobile.svg"

const NotFoundPage = ({ data }) => {

  const posts = data.allWpPost.nodes

  return (
    <div className="w-screen xl:h-screen flex flex-col xl:flex-row">

      <Seo title="404: Not Found" />

      <Layout>

        <h1 className="my-10 text-xl text-center xl:text-left xl:m-0 xl:mb-2">Experimente ler alguns dos nossos últimos artigos.</h1>

        <section>

          <ol className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {posts.map((post, i) => <CardPost key={i} post={post} />)}
          </ol>

        </section>

      </Layout>

      <aside className="w-full xl:w-5/12 h-96 xl:h-screen flex items-center justify-center mt-16 xl:mt-0 bg-greenQS order-first">
        <img src={error404} alt="erro 404" className="hidden xl:flex w-10/12" />
        <img src={erro404mobile} alt="erro 404 pagina não encontrado" className="xl:hidden h-5/6" />
      </aside>

    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query posts404 {
    allWpPost(limit: 6) {
      nodes {
        title
        date(formatString: "DD/MM/YYYY")
        uri
        
        categories {
          nodes {
            name
          }
        }

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 574,
                  placeholder: BLURRED,
                  formats: [WEBP],
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
  }
`
